<template>
  <v-container
    fluid
    fill-height
  >
    <v-menu
      open-on-hover
      bottom
      right
      offset-y
      transition="slide-y-transition"
      style="position: absolute; right: 0; top: 0; padding: 15px;"
    >
      <router-link
        v-ripple
        slot="activator"
        class="toolbar-items"
        to=""
      >
        <v-badge
          color="error"
          overlap
        >
          <span style="text-transform: uppercase; color: rgba(0,0,0,.87);">{{ selectedLocale.locale }}</span>
        </v-badge>
      </router-link>
      <v-card>
        <v-list dense>
          <v-list-tile
            v-for="language in languages"
            :key="language.locale"
            @click="changeLanguage(language)"
          >
            <v-list-tile-title
              v-text="$t(language.name)"
            />
          </v-list-tile>
        </v-list>
      </v-card>
    </v-menu>
    <v-layout
      align-center
      justify-center
    >
      <v-flex
        xs12
        sm8
        md4
      >
        <v-card class="elevation-12">
          <v-toolbar
            :color="'primary'"
            flat
          >
            <v-toolbar-title style="text-align: center;">
              <img
                src="../../assets/StockManager.png"
                width="50%"
                style="width: 100%; max-width: 200px; padding-top: 5px">
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <div style="position: relative;">
                <v-text-field
                  :rules="[requiredValue, checkRulePassword, sameValue(newPassword, confirmNewPassword)]"
                  v-model="newPassword"
                  :label="$t('forgotPassword.newPassword')"
                  :name="$t('forgotPassword.newPassword')"
                  :type="isShowNewPassword ? 'text' : 'password'"
                  prepend-icon="mdi-lock"
                  class="required"
                  loading
                  @keyup.enter="onConfirmResetPassword($event)"
                  @input="validatePassword(newPassword)"
                >
                  <template v-slot:progress>
                    <v-progress-linear
                      :value="progress"
                      :color="color"
                      absolute
                      height="7"
                    />
                  </template>
                </v-text-field>
                <div
                  v-show="passwordTyped"
                  class="lnu_container">
                  <p :class="{ got_number: containNumber }">
                    {{ $t("user.digit") }}
                  </p>
                  <p :class="{ got_lowercase: containLowercase }">
                    {{ $t("user.lowercase") }}
                  </p>
                  <p :class="{ got_uppercase: containUppercase }">
                    {{ $t("user.uppercase") }}
                  </p>
                </div>
                <v-icon
                  style="position: absolute; right: 0; top: 25%; cursor: pointer;"
                  @mousedown="showPassword('old')"
                  @mouseleave="hidePassword('old')"
                  @mouseup="hidePassword('old')">{{ isShowNewPassword ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
              </div>
              <div style="position: relative; padding-top: 10px">
                <v-text-field
                  :rules="[requiredValue, sameValue(newPassword, confirmNewPassword)]"
                  v-model="confirmNewPassword"
                  :label="$t('forgotPassword.confirmPassword')"
                  :name="$t('forgotPassword.confirmPassword')"
                  :type="isShowConfirmPassword ? 'text' : 'password'"
                  prepend-icon="mdi-lock"
                  class="required"
                  @keyup.enter="onConfirmResetPassword($event)"
                />
                <v-icon
                  style="position: absolute; right: 0; top: 25%; cursor: pointer;"
                  @mousedown="showPassword('new')"
                  @mouseleave="hidePassword('new')"
                  @mouseup="hidePassword('new')">{{ isShowConfirmPassword ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
              </div>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="success"
              @click="onConfirmResetPassword($event)">{{ $t('common.confirm') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <loading-bar :is-loading="isLoading"/>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar" />
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import ToastType from 'enum/toastType'
import functionUtils from 'utils/functionUtils'
export default {
  data: () => ({
    valid: true,
    newPassword: '',
    confirmNewPassword: null,
    isLoading: false,
    isShowNewPassword: false,
    isShowConfirmPassword: false,
    //
    passwordLength: 0,
    passwordTyped: false,
    containLowercase: false,
    containUppercase: false,
    containNumber: false,
    validPasswordLength: false,
    validPassword: false
    //
  }),
  computed: {
    progress () {
      return Math.min(100, this.newPassword.length * 12.5)
    },
    color () {
      return ['error', 'warning', 'success'][Math.floor(this.progress / 40)]
    },
    ...mapGetters(['GET_SNACK_BAR', 'languages', 'selectedLocale'])
  },
  methods: {
    /**
     * Show password
     */
    showPassword (type) {
      if (type === 'old') {
        this.isShowNewPassword = true
      } else {
        this.isShowConfirmPassword = true
      }
    },
    /**
     * Hide password
     */
    hidePassword (type) {
      if (type === 'old') {
        this.isShowNewPassword = false
      } else {
        this.isShowConfirmPassword = false
      }
    },
    // Require value
    requiredValue (value) {
      if (value !== null && value !== undefined && value.length !== 0) {
        return true
      }
      return this.$t('common.requiredFields')
    },
    // Validate old and new password
    sameValue (newPassword, confirmNewPassword) {
      if ((newPassword != null && confirmNewPassword != null) && newPassword !== confirmNewPassword) {
        return this.$t('register.passwordIncorrect')
      }
      return true
    },
    /**
     * Change language
     */
    changeLanguage (language) {
      this.$i18n.locale = language.locale
      this.$store.dispatch('changeLanguage', language)
    },
    // Validate
    validate () {
      if (this.$refs.form.validate()) {
        let token = this.$route.params.token
        let uid = this.$route.params.uid
        let filter = {
          newPassword: this.newPassword,
          confirmPassword: this.confirmNewPassword,
          uidb64: uid,
          token: token
        }
        this.isLoading = true
        this.CONFIRM_RESET_PASSWORD(filter).then(
          function () {
            this.isLoading = false
            this.ON_SHOW_TOAST({
              'message': this.$t('common.success'),
              styleType: ToastType.SUCCESS
            })
            this.$router.push({
              path: '/login'
            })
          }.bind(this)
        ).catch(
          function (error) {
            this.isLoading = false
            if (!functionUtils.isNull(error.response.data.message)) {
              let errorText = error.response.data.message
              this.ON_SHOW_TOAST({
                'message': this.$t(`${errorText}`),
                styletype: ToastType.ERROR
              })
            } else {
              this.ON_SHOW_TOAST({
                'message': this.$t('login.somethingWentWrong'),
                'styleType': ToastType.ERROR
              })
            }
          }.bind(this)
        )
      }
    },
    /**
     * Go dashboard
     */
    onConfirmResetPassword: function (event) {
      event.preventDefault()
      this.validate()
    },
    //
    validatePassword: function (password) {
      this.passwordLength = password.length

      if (this.passwordLength > 7) {
        this.validPasswordLength = true
      } else {
        this.validPasswordLength = false
      }

      if (this.passwordLength > 0) {
        this.passwordTyped = true
      } else {
        this.passwordTyped = false
      }

      this.containLowercase = /[a-z]/.test(password)
      this.containUppercase = /[A-Z]/.test(password)
      this.containNumber = /\d/.test(password)

      if (
        this.containLowercase === true &&
        this.containUppercase === true &&
        this.containNumber === true &&
        this.validPasswordLength === true
      ) {
        this.validPassword = true
      } else {
        this.validPassword = false
      }
    },
    checkRulePassword: function () {
      if (!this.validPassword) {
        return this.$t('user.ChangePasswordModal.weakPassError')
      }
      return true
    },
    ...mapActions(['ON_SHOW_TOAST', 'CONFIRM_RESET_PASSWORD'])
  }
}
</script>
<style lang="scss" scoped>
.translate-toolbar .translate-toolbar__content{
  justify-content: flex-end !important;
  float: right;
}
/deep/.required label::after {
  content: " *";
  color: red;
}

.v-card__actions {
  display: flex;
  justify-content: flex-end;
}

//
.lnu_container {
  display: block;
  margin: 0px auto;
  margin-bottom: 0;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.lnu_container p {
  width: 30%;
  height: auto;
  font-size: 12px !important;
  line-height: 1.2;
  text-align: center;
  border-radius: 2px;
  color: rgba(71, 87, 98, 0.8);
  background: linear-gradient(to right, #36996b 50%, #eee 50%);
  background-size: 200% 100%;
  background-position: right;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
  margin-bottom: 5px;
}
.got_lowercase,
.got_uppercase,
.got_number {
  background-position: left !important;
  color: rgba(255, 255, 255, 0.9) !important;
}
.show_password_length {
  display: block !important;
}
</style>
